import _common2 from "./common";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _common = _common2;

var _default = (0, _common.withParams)({
  type: "ipAddress"
}, function (value) {
  if (!(0, _common.req)(value)) {
    return true;
  }

  if (typeof value !== "string") {
    return false;
  }

  var nibbles = value.split(".");
  return nibbles.length === 4 && nibbles.every(nibbleValid);
});

exports.default = _default;

var nibbleValid = function nibbleValid(nibble) {
  if (nibble.length > 3 || nibble.length === 0) {
    return false;
  }

  if (nibble[0] === "0" && nibble !== "0") {
    return false;
  }

  if (!nibble.match(/^\d+$/)) {
    return false;
  }

  var numeric = +nibble | 0;
  return numeric >= 0 && numeric <= 255;
};

export default exports;