import _common2 from "./common";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _common = _common2;

var _default = (0, _common.regex)("decimal", /^[-]?\d*(\.\d+)?$/);

exports.default = _default;
export default exports;