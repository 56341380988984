import _common2 from "./common";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _common = _common2;

var _default = function _default(prop) {
  return (0, _common.withParams)({
    type: "requiredIf",
    prop: prop
  }, function (value, parentVm) {
    return (0, _common.ref)(prop, this, parentVm) ? (0, _common.req)(value) : true;
  });
};

exports.default = _default;
export default exports;