import _common2 from "./common";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _common = _common2;

var _default = function _default(validator) {
  return (0, _common.withParams)({
    type: "not"
  }, function (value, vm) {
    return !(0, _common.req)(value) || !validator.call(this, value, vm);
  });
};

exports.default = _default;
export default exports;